import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { fill } from "lodash";
import "./Pagination.scss";

const Pagination = props => {

    const { currentPage, pageCount } = props.pageContext;
    const isFirst = currentPage === 1 || !currentPage;
    const isLast = currentPage === pageCount;
    const prevPage = "/blogs/" + (currentPage - 1 > 1 ? (currentPage - 1) : "");
    const nextPage = "/blogs/" + (currentPage + 1);

    const verticalAlignment = { paddingTop: "0.45em" }
    

    const visiblePageNumbers = selectRelevantPageLinks(currentPage, pageCount)
    // console.log("==currentPage is ")
    // console.log(currentPage)

    // console.log("==pageCount is ")
    // console.log(pageCount)
    return (
        <React.Fragment>
            <div className="pagination">

                {/* "Prev" arrow */}
                {!isFirst && (
                    <Link to={prevPage} rel="prev" style={verticalAlignment} >
                        <span className="prev-arrow">
                            <FaArrowLeft/>
                        </span>
                    </Link>
                )}

                {/* Numbered page links. */}
                {pageCount > 1 && (
                    <React.Fragment>
                        {visiblePageNumbers.map(num => {
                            if (isNaN(num)) {
                                return <span key={`dots-${num}`}>.....</span>
                            }
                            return (
                                <span className="pagination-numbers" key={`page-${num}`} >
                                    <Link
                                        to={`/blogs/${num === 1 ? "" : num}`}
                                        style={{
                                            padding: "3px 8px",
                                            borderRadius: "5px",
                                            textDecoration: "none",
                                            color: num === currentPage ? "#ffffff" : "#666",
                                            background: num === currentPage ? "#212121" : "",
                                            lineHeight: "30px",
                                            verticalAlign: "middle"
                                        }}
                                        className="pagination-numbers"
                                    >
                                        {num}
                                    </Link>
                                </span>
                            )
                        })}
                    </React.Fragment>
                )}

                {/* "Next" arrow */}
                {!isLast && (
                    <Link to={nextPage} rel="next" style={verticalAlignment} >
                        <span className="next-arrow">
                            <FaArrowRight/>
                        </span>
                    </Link>
                )}


            </div>
            {/* <style>{`
                
                
            `}
            </style> */}
        </React.Fragment>
    );
}

function selectRelevantPageLinks(currentPage, countPages) {
    var visiblePageNumbers = []
    if (countPages <= 2) {
        /* If there are not too much, show everything. */
        for (let i=1; i<=countPages; i++) {
            visiblePageNumbers.push(i)
        }
    } else {
        /* Always show beginning, end, current, and around current. */
        if (currentPage <= 4) { // this can't be less than 3
            /* If beginning and current are not too far, we don't want to "dot dot" between them. */
            for (let i=1; i<currentPage; i++) {
                visiblePageNumbers.push(i)
            }
        } else {
            visiblePageNumbers.push(1)
            visiblePageNumbers.push("dots-left-half")
            visiblePageNumbers.push(currentPage-2)
            visiblePageNumbers.push(currentPage-1)
        }
        visiblePageNumbers.push(currentPage)
        if (currentPage >= countPages-3) {
            /* If current and end are not too far, we don't want to "dot dot" between them. */
            for (let i=currentPage+1; i<countPages; i++) {
                visiblePageNumbers.push(i)
            }
        } else {
            visiblePageNumbers.push(currentPage+1)
            visiblePageNumbers.push(currentPage+2)
            visiblePageNumbers.push("dots-right-half")
        }
        if (currentPage !== countPages) {
            visiblePageNumbers.push(countPages)
        }
    }
    return visiblePageNumbers
}

Pagination.propTypes = {
    pageContext: PropTypes.object.isRequired 
};

export default Pagination;