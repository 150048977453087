import React, { useEffect } from "react";
import PostPreview from "../PostPreview";

const PostListing = ({postEdges}) => {

  useEffect(() => {
  
    const Valine = require("valine");
    new Valine().init({
        el: `#vcomments`,
        path: window.location.pathname,
        appId: 'o2kfEKPV0HsjkXcXqQbiEAHp-MdYXbMMI',
        appKey: 'nwrerESqIlNKDC1p9l2bxOa8',
        placeholder: 'Your comment ...',
        avatar: 'robohash',
      //   pageSize: 10,
        lang: 'en',
        visitor: true,
      //   recordIP: true,
      });
    
  })

  const postList = [];
  postEdges.forEach(postEdge => {
    postList.push({
      path: postEdge.node.fields.slug,
      tags: postEdge.node.frontmatter.tags,
      cover: postEdge.node.frontmatter.cover,
      title: postEdge.node.frontmatter.title,
      date: postEdge.node.fields.date,
      excerpt: postEdge.node.excerpt,
      timeToRead: postEdge.node.timeToRead
    });
  });

  return (
    <div className="md-grid md-grid--no-spacing md-cell--middle">
        <div className="md-grid md-cell--8 mobile-fix">
          {postList.map(post => (
            <PostPreview key={post.title} postInfo={post} />
          ))}
        </div>
      </div>
  );

}

// class PostListing extends React.Component {
//   getPostList() {
//     const postList = [];
//     this.props.postEdges.forEach(postEdge => {
//       postList.push({
//         path: postEdge.node.fields.slug,
//         tags: postEdge.node.frontmatter.tags,
//         cover: postEdge.node.frontmatter.cover,
//         title: postEdge.node.frontmatter.title,
//         date: postEdge.node.fields.date,
//         excerpt: postEdge.node.excerpt,
//         timeToRead: postEdge.node.timeToRead
//       });
//     });
//     return postList;
//   }
//   render() {
//     const postList = this.getPostList();
//     return (
//       <div className="md-grid md-grid--no-spacing md-cell--middle">
//         <div className="md-grid md-cell--8 mobile-fix">
//           {postList.map(post => (
//             <PostPreview key={post.title} postInfo={post} />
//           ))}
//         </div>
//       </div>
//     );
//   }
// }

export default PostListing;
